import React from "react";
import Grid from '@mui/material/Grid';
import './TheProcess.css';
import process1 from '../../../assets/process1.jpeg';
import process2 from '../../../assets/imgProcess4.jpg'
import process3 from '../../../assets/process3.jpeg'
import process4 from '../../../assets/process4.jpg'
import process5 from '../../../assets/process5.jpg'
import process6 from '../../../assets/process6.jpg'
import process7 from '../../../assets/process7.jpg'


const TheProcess = () => {
    return (
        <Grid container sx={12} md={10} className='theProcessSection'>
            <Grid item sx={12} md={10} className='theProcessContainer '>
                <Grid item xs={10} md={10}>
                    <Grid item md={10} sx={8}>
                        <p className="theProcessTitle">
                            La exploración es el comienzo de todo proyecto minero.
                        </p>
                    </Grid>
                    <Grid item md={10} sx={8}>
                        <p className="theProcessText">
                            En este te hace un reconocimiento del suelo, con
                            ingenieros y geólogos, quienes hacen un estudio de la
                            composición del suelo y la ubicación y dirección de las
                            vetas del mineral a extraer. Esto se hace para no solo
                            para minimizar recursos, también se tiene en cuenta
                            que se está haciendo una extracción de un recurso, y
                            no la erosión del lugar.
                        </p>
                    </Grid>
                </Grid>
                <Grid item xs={10} md={10} className='divImgMobile'>
                    <img src={process1} className='imgProcess1'/>
                </Grid>
            </Grid>
            <Grid item md={10} xs={12} className='theProcessContainer '>
                <Grid item sx={12} md={6} className='specialDiv specialClass' >
                    <Grid item xs={10} md={10} className='divImgMobile'>
                        <img src={process2} className='imgProcess2'/>
                    </Grid>
                    <Grid item xs={10} md={10} >
                        <p className="theProcessTitle">
                            La extracción
                        </p>
                        <Grid item md={12} xs={10}>
                            <p className="theProcessText">
                                Se realiza con una voladura controlada que variará
                                según la cantidad y el tamaño del producto requerido
                                en esta primera instancia. Si es necesario se hace un
                                petardeo para llegar al resultado requerido.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} className='specialDiv specialClass'>
                    <Grid item xs={10} md={10} className='divImgMobile'>
                        <img src={process3} className='imgProcess3'/>
                    </Grid>
                    <Grid item xs={10} md={10} justifyContent='center'>
                        <p className="theProcessTitle">
                            El transporte hacia la planta de trituración
                        </p>
                        <Grid item md={12} xs={12}>

                            <p className="theProcessText">
                                Se hace a través de palas cargadoras que depositan la
                                piedra en volquetes, que generalmente trasladan
                                el producto a una trituradora primaria.
                            </p>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
            <Grid item md={10} sx={10} className='img456 theProcessContainer'>
                <Grid item xs={10} className='divImgMobile'>
                    <img src={process4} className='imgProcess4'/>
                </Grid>
                <Grid item xs={10} className='divImgMobile'>
                    <img src={process5} className='imgProcess5'/>
                </Grid>
                <Grid item xs={10} className='divImgMobile'>
                    <img src={process6} className='imgProcess6'/>
                </Grid>
            </Grid>
            <Grid item xs={12} md={10} className='theProcessContainer'>
                <Grid item xs={10} md={5.88}>
                    <Grid item xs={12} md={12} justifyContent='center'>
                        <p className="theProcessTitle">
                            La trituración
                        </p>
                        <p className="theProcessText">
                            La piedra pasa por una primera trituración y luego se
                            traslada por cintas hacia distintas zarandas y
                            trituradoras secundarias y terciarias, depositándose el
                            material en pilas según su granulometría.
                        </p>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <p className="theProcessTitle">
                            El lavado
                        </p>
                        <p className="theProcessText">
                            En ciertos casos se realiza un lavado del material para
                            obtener áridos limpios para ciertas aplicaciones.
                        </p>
                    </Grid>
                </Grid>
                <Grid item display='flex' sx={10} md={6} justifyContent='center'>
                    <img src={process7} className='imgProcess7'/>
                </Grid>
            </Grid>
        </Grid>

    )
}

export default TheProcess