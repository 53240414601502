import React from "react";
import Grid from '@mui/material/Grid';
import basics1 from '../../../assets/basics1.jpg';
import basics2 from '../../../assets/basics2.jpg';
import basics3 from '../../../assets/basics3.jpg';
import basics4 from '../../../assets/basics4.jpg';
import basics5 from '../../../assets/basics5.jpg';
import './Basics.css'

const Basics = () => {
    return (
        <Grid container xs={10} md={10} className='basicsSection'>
            <Grid item sx={4} md={10} className='basicsMineria specialDivBasics'>
                <Grid item xs={12} md={10}>
                    <img src={basics1} className='imgBasics1'/>
                </Grid>
                <Grid item xs={12} md={10}>
                    <Grid item xs={10} md={6}>
                    <p className="basicsTitle">
                        La Minería
                    </p>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <p className="basicsSubtitle">
                            La Minería es una actividad productiva 
                            necesaria que respeta y mejora la 
                            calidad de vida de todos nosotros.
                            Siempre ha estado al servicio y 
                            bienestar de la Humanidad ya que 
                            nació, creció se desarrolla con ella.
                        </p>
                    </Grid>
                    <p className="basicsSubtitleColor">
                        La Minería pone a disposición de la Humanidad las materias primas minerales que ésta necesita para su bienestar. El hombre las busca en la parte accesible de la corteza terrestre, las extrae, las utiliza en forma natural y/o las prepara para su utilización.
                    </p>
                    <Grid item xs={10} md={12}>
                        <p className="basicsText"> 
                            La Minería está en todas partes, sus productos en
                            estado natural y los que de ellos derivan, que son miles  
                            de miles, se vinculan tanto a los seres humanos, que
                            hoy en día no se concibe poder vivir sin ellos. Nació  
                            prácticamente con el hombre. Esto es así porque las  
                            distintas edades primigenias marcan su desarrollo junto 
                            a él con mención a productos de la Minería: Edad de  
                            Piedra, de Cobre, de Bronce y de Oro.
                        </p>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={10} sx={6} className='basicsMineriaSecond'>
                <Grid item sx={4} md={6}>
                    <img src={basics2} className='imgBasics2'/>
                </Grid>
                <Grid item xs={12} md={5} className='specialDiv'>
                    <p className="basicsText">
                        En base a muchísimos análisis se ha determinado que la  
                        corteza de la tierra, hasta una profundidad de unos 16  
                        kilómetros, está compuesta en un 99,29 % por 14  
                        elementos** que, de mayor a menor, son: Oxígeno,  
                        Silicio, Aluminio, Hierro, Calcio, Sodio, Potasio, Magnesio, Hidrógeno, Titanio, Cloro, Carbón, Fósforo y   
                        Azufre.
                    </p>
                    <p className="basicsText">
                        Los átomos de estos grandes elementos y unos 60   
                        más, que conforman la fracción remanente, se   
                        combinan entre sí para formar moléculas (la parte más   
                        pequeña que puede existir de un cuerpo en estado   
                        libre) y ocurren en unos 1500 compuestos químicos   
                        diferentes que se conocen como Minerales.
                    </p>
                    <p className="basicsText">
                        Los minerales se encuentran bajo una gran variedad de condiciones y diferentes grupos de asociaciones. Las asociaciones de minerales forman las distintas Rocas.
                    </p>
                    <p className="basicsText">
                        Una Roca es un mineral o agregado de minerales que conforman una parte esencial de la tierra; pero algunos cuerpos minerales importantes, tales como ciertos yacimientos de metales no son considerados como Rocas.
                    </p>
                    <p className="basicsText">
                        La Minería de Argentina provee Minerales Metalíferos como el Cobre, Plomo, Zinc, Aluminio, Oro, Plata, Litio. Dentro de los Minerales No Metálicos produce Arcillas, Azufre, Cuarzo, Micas, Boratos, Sal Común. Sus Rocas de Aplicación o de Construcción incluyen a la Arena, Caliza, Yeso, Mármol, Pizarra, Granitos y Conchillas. Además tenemos los Combustibles Sólidos, Líquidos y Gaseosos y Piedras Preciosas y Semipreciosas.
                    </p>
                </Grid>
            </Grid>
            <Grid item sx={4} md={10} className='basicsExtraccion'>
                <Grid item sx={4} md={6}>
                    <Grid item sx={4} md={6}>
                        <img src={basics3}  className='imgBasics3'/>
                    </Grid>
                    <Grid item xs={10} md={10}>
                        <p className="basicsTitle">
                            Extracción de los Minerales
                        </p>
                        <p className="basicsText">
                            En un depósito mineral dado, las operaciones mineras se dividen en 4 etapas que son correlativas:
                        </p>
                        <ul>
                            <li className="basicsText">
                                Gestión en Canteras Olavarría, Ciudad de Olavarría, Pcia. de Bs. As., Argentina
                            </li>
                            <li className="basicsText">
                                2° Congreso de Áridos, Valencia, España
                            </li>
                            <li className="basicsText">
                                VII Jornadas de Iberoamericanas de materiales para la construcción, Valencia, España.
                            </li>
                            <li className="basicsText">
                                Asamblea Anual de la Federación Iberoamericana de Productores de Áridos, Valencia, España.
                            </li>
                            <li className="basicsText">
                                Expo Rural de Olavarría, Pcia. Bs. As., Argentina.
                            </li>
                        </ul>
                    </Grid>
                </Grid>
                <Grid item sx={12} md={6}>
                    <Grid item sx={12} md={8}>
                        <img src={basics4}  className='imgBasics4'/>
                    </Grid>
                    <Grid item sx={12} md={10}>
                        <p className="basicsText">
                            Este último proceso no tiene ninguna vinculación con  
                            los Sistemas de Explotación (Subterráneos y a Cielo  
                            Abierto) que son los que utilizan diversos métodos para  
                            el arranque y extracción o transporte del mineral de la  
                            mina.  
                            La Explotación comprende todas las labores, ya sean  
                            <b style={{fontWeight: 700}}> Subterráneas o a Cielo Abierto,</b> para la extracción de <b style={{fontWeight: 700}}>las 
                            materias minerales sólidas, líquidas o gaseosas. </b> 
                            Las minas Subterráneas son aquellas en que los  
                            minerales se extraen dentro de los suelos (rocas) sin  
                            tener contacto con la luz del sol. Tienen techo.  
                            Las minas a<b style={{fontWeight: 700}}> Cielo abierto</b> son aquellas que se excavan  
                            desde y en la superficie y no tienen techo.
                        </p>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sx={4} md={10}  className='basicsThird'>
                <Grid item sx={4} md={6}>
                    <img src={basics5} className='imgBasics5'/>
                </Grid>
                <Grid item xs={12} md={6} className='specialAtributsBasics'>
                    <Grid item xs={12} md={10}>
                        <p className="basicsText">
                            Hasta los comienzos del siglo pasado la técnica minera  
                            era casi totalmente empírica. A partir de entonces fue   
                            posible, gracias a una cuidadosa y minuciosa valoración   
                            de las experiencias prácticas, observaciones  
                            metódicas, experimentos y cálculos, dar al laboreo   
                            minero una base científica. En su perfeccionamiento se  
                            continúa trabajando sin interrupción para mejorar cada  
                            vez más los distintos procesos de la Minería,  
                            planificarlos con más perfección y predecir con mayor   
                            exactitud su desarrollo.
                        </p>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <p className="basicsText">
                            El campo de acción del Laboreo de Minas es "La  
                            Corteza de la Tierra", con sus fuerzas naturales  
                            contenidas, cuyas manifestaciones sorprenden al  
                            hombre siempre de nuevo: terremotos, inundaciones,  
                            sequías, olas de frío o de calor, tifones, huracanes,  
                            erupciones volcánicas y deriva de los continentes,  
                            fenómenos tectónicos que provocan levantamientos,  
                            hundimientos, fracturas, desplazamientos, el ciclo   
                            erosivo terrestre que provoca modelados típicos de los  
                            suelos con fenómenos de meteorización que originan   
                            continuos cambios en su relieve, clima, flora y fauna,  
                            que han ocurrido y ocurrirán a lo largo de los miles de   
                            milenios venideros.
                        </p>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}


export default Basics