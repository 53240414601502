import React, { forwardRef, useState } from "react";
import { Grid, Button, TextField, Input } from "@mui/material"
import { useEffect } from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { loginAdm, logOut } from "../../service/ServiceAbm.jsx";
import Footer1 from '../../assets/footer1.jpeg'
import Footer2 from '../../assets/footer2.jpeg'
import Footer3 from '../../assets/footer3.jpeg'
import Footer4 from '../../assets/footer4.jpeg'
import Footer5 from '../../assets/footer5.jpeg'
import './Footer.css'

const Footer = () => {
    function refreshPage() {
        window.location.reload(false);
    }
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    let [username, setUsername] = useState()
    let [password, setPassword] = useState()

    const setFullUser = (user) => {
        setUsername(username + user.data)
    }
    const setFullPass = (pass) => {
        setPassword(password + pass.data)
    }
    const login = () => {
        loginAdm(username, password)
    }


    const handleKeyPress = (event) => {
        if (event.charCode === 13) {
            login();
        }
    };

    const style = {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        borderRadius: '14px',
        top: '50%',
        left: '50%',
        width: '500',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 6,
    }

    return (
        <>
            <Grid containermd={12} sx={12} className='footerContainer specialCssFooter'>
                <Grid className="allianceClass" md={8} xs={12}>
                    <p style={{ fontWeight: 700, fontSize: '24px', marginLeft: '10px' }}> Alianzas Estratégias</p>

                </Grid>
                <Grid container className='imgContainerFooter specialClassFooter' style={{ marginBottom: '45px' }} md={12} xs={12}>
                    <Grid item xs={2} md={2} className='specialCssImg'>
                        <img src={Footer1} className='imgFooter1' />
                    </Grid>
                    <Grid item xs={2} md={2} className='specialCssImg'>
                        <img src={Footer2} className='imgFooter2' />
                    </Grid>
                    <Grid item xs={2} md={2} className='specialCssImg'>
                        <img src={Footer3} className='imgFooter3' />
                    </Grid>
                    <Grid item xs={2} md={2} className='specialCssImg'>
                        <img src={Footer4} className='imgFooter4' />
                    </Grid>
                    <Grid item xs={2} md={2} className='specialCssImg'>
                        <img src={Footer5} className='imgFooter5' />
                    </Grid>

                </Grid>
                <Grid md={8} xs={10} className={'footer'} alignItems='flex-end'>
                    <Grid item display='flex' flexDirection='column' md={10} xs={10}>
                        <Grid item display='flex' flexDirection='row' xs={10}>
                            <p className="textTitleFooter" style={{ color: "#C9773D" }} >CÁMARA</p>
                            <p className="textTitleFooter" style={{ color: "#B2B2B2" }} >DE</p>
                            <p className="textTitleFooter" style={{ color: "#9D9D9C" }} >LA</p>
                            <p className="textTitleFooter" style={{ color: "#C9773D" }}>PIEDRA</p>

                        </Grid>
                        <Grid item display='flex' style={{ justifyContent: 'flex-start' }}>
                            <p className="textSubTitleFooter" style={{ color: '#706F6F' }}>DE LA PROVINCIA DE BUENOS AIRES</p>
                        </Grid>
                    </Grid>
                    <Grid item md={10} xs={12} >
                        <Grid item  >
                            <p className="textFooter">
                                Cámara de la Piedra de la Provincia de Buenos Aires <br />
                                Sarmiento 347 2°11 - CABA - CP 1041
                            </p>
                        </Grid>
                        <Grid item >
                            <p className="textFooter">
                                Teléfono: 011-4702-8205
                                Email:info@camaradelapiedra.org.ar <br />
                                camaradelapiedra.bsas@gmail.com
                            </p>
                        </Grid>
                    </Grid>
                    {
                        localStorage.getItem("token")
                            ?
                            <Button variant="contained" style={{ backgroundColor: 'white', color: "#BC6C25" }} onClick={logOut}>Logout</Button>
                            :
                            <Button variant="contained" style={{ backgroundColor: 'white', color: "#BC6C25" }} onClick={handleOpen}>Ingresar</Button>
                    }
                    <Grid item display='flex' style={{ justifyContent: 'flex-end' }}>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Grid sx={style} xs={4} md={6} className={'modalLoginFooter'}>
                                <p>Username</p>
                                <Input id="username" type="text" onChange={(evt) => { setUsername(evt.target.value) }} />
                                <p>Password</p>
                                <Input id="password" type="password" onChange={(evt) => { setPassword(evt.target.value) }} onKeyPress={(evt) => {
                                    if (evt.key === 'Enter') {
                                        login();
                                    }
                                }} />
                                <Button variant="contained" style={{ width: '154px', marginTop: '15px', justifyContent: 'center' }} onClick={() => {
                                    handleClose();
                                    login();
                                }}>Ingresar</Button>
                            </Grid>
                        </Modal>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Footer