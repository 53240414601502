import React, { useState, useRef } from "react";
import { Button, ButtonGroup, Grid, Input } from "@mui/material";
import { useEffect } from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserMinus } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { getSocios } from "../../service/ServiceAbm.jsx";
import { getSocioById } from "../../service/ServiceAbm.jsx";
import { borrarSocio } from "../../service/ServiceAbm.jsx";
import { editarSocio } from "../../service/ServiceAbm.jsx";
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import './TablaSocios.css'
import { height } from "@mui/system";

const TablaSocios = ({ limit }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleCloseDelete = () => setModalDelete(false);
    const [email, setEmail] = useState()
    const [razonSocial, setRazonSocial] = useState()
    const [residencia, setResidencia] = useState()
    const [domicilio, setDomicilio] = useState()
    const [telefono, setTelefono] = useState()
    const [codigoPostal, setCodigoPostal] = useState()
    const [localidad, setLocalidad] = useState()
    const [contacto, setContacto] = useState()
    const [web, setWeb] = useState()
    const [ubicacion, setUbicacion] = useState()
    const [data, setData] = useState()
    const [modalDelete, setModalDelete] = useState(false)
    const [idSocio, setIdSocio] = useState()
    const [isEdit, setIsEdit] = useState(false)
    const [drawerOpen, setDrawerOpen] = useState(false);

    const getData = async (limit) => {
        const response = await getSocios(limit)
        setData(response)
    };
    useEffect(() => {

        getData(limit)
    }, []);

    const getDataSocio = async (id) => {
        const response = await getSocioById(id)
        setIdSocio(id)
        setRazonSocial(response.razon)
        setResidencia(response.residencia)
        setDomicilio(response.domicilio)
        setTelefono(response.telefono)
        setCodigoPostal(response.cp)
        setEmail(response.emails)
        setLocalidad(response.localidad)
        setUbicacion(response.ubicacionG)
        setWeb(response.web)
        setContacto(response.contacto)
    }
    const style = {
        display: 'flex',
        flexDirection: 'column',
        flexWarp: 'warp',
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: 'auto',
        height: 'auto',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: 'none',
        p: 2,
    }
    const handleSubmit = (event) => {
        event.preventDefault(); // Evita que se recargue la página al enviar el formulario
        handleClose();
        editarSocio(idSocio, razonSocial, residencia, domicilio, telefono, codigoPostal, localidad, email, contacto, web, ubicacion);
        handleKeyDown(event)
        getData(5)
      };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      }
      

    if (data !== undefined) {
        return (
            <><Grid md={12} xs={12} className="containerRenderTable">
                {data.map((item, index) => (
                    <>
                        <td className="tdContainerRenderTable" key={index}>
                            <p className="razonTable">
                                {item.razon}
                            </p>
                            <p className="genericTextTable displayNoneAbmMobile">
                                {item.domicilio}
                            </p>
                            <Grid item className="displayNoneAbmDesktop">
                                <li className="thTable">Telefono</li>
                                <p className="genericTextTable">
                                    {item.telefono?.replace(' ', ' / ')}
                                </p>
                            </Grid>
                            <Grid item className="displayNoneAbmMobile">
                                <p className="genericTextTable" style={{ width: '120px' }}>
                                    {item.telefono}
                                </p>
                            </Grid>
                            <p className="genericTextTable displayNoneAbmMobile">
                                {item.emails}
                            </p>
                            <p className="genericTextTable">
                                <li className="thTable displayNoneAbmDesktop">Web</li>

                                {item.web}
                            </p>
                            <p className="genericTextTable displayNoneAbmMobile">
                                {item.ubicacionG}
                            </p>
                            {localStorage.getItem('token') &&
                                <>
                                    <Button onClick={() => {
                                        setIdSocio(item.id);
                                        setIsEdit(true);
                                        handleOpen();
                                        getDataSocio(item.id);
                                    }}
                                    style={{alignItems: "center"}}
                                    >
                                        <ModeEditOutlineOutlinedIcon></ModeEditOutlineOutlinedIcon>
                                    </Button>

                                    <Button onClick={() => {
                                        setIdSocio(item.id);
                                        setModalDelete(true);
                                    }}>
                                        <DeleteOutlineOutlinedIcon color="error"></DeleteOutlineOutlinedIcon>
                                    </Button></>}
                        </td>
                        <hr color="#BC6C25"></hr>
                    </>
                ))}
                <Grid>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style} xs={8} md={12} className={'modalAmbSocios'}>
                            <Button style={{ width: '24px', backgroundColor: 'white' }} variant="contained" onClick={() => { handleClose(); }}>
                                <FontAwesomeIcon onCle={handleClose} icon={faArrowLeftLong} fontSize='25px' color="#BC6C25" />
                            </Button>
                            <form onSubmit={handleSubmit}>
                            <Grid md={12} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>
                                <Grid style={{ width: '50%' }}>
                                    <p>Razon Social</p>
                                    <Input required id="RazonSocia" style={{ width: '90%' }} type="text" onChange={(evt) => { setRazonSocial(evt.target.value); }} value={razonSocial} />
                                </Grid>
                                <Grid style={{ width: '50%' }}>
                                    <p>Residencia</p>
                                    <Input id="Residencia" style={{ width: '90%' }} type="text" onChange={(evt) => { setResidencia(evt.target.value); }} value={residencia} />
                                </Grid>
                                <Grid style={{ width: '50%' }}>
                                    <p>Domicilio</p>
                                    <Input id="Domicilio" style={{ width: '90%' }} type="text" onChange={(evt) => { setDomicilio(evt.target.value); }} value={domicilio} />
                                </Grid>
                                <Grid style={{ width: '50%' }}>
                                    <p>Telefono</p>
                                    <Input id="Telefono" style={{ width: '90%' }} type="text" onChange={(evt) => { setTelefono(evt.target.value); }} value={telefono} />
                                </Grid>
                                <Grid style={{ width: '50%' }}>
                                    <p>CP</p>
                                    <Input id="CP" type="text" style={{ width: '90%' }} onChange={(evt) => { setCodigoPostal(evt.target.value); }} value={codigoPostal} />
                                </Grid>
                                <Grid style={{ width: '50%' }}>
                                    <p>Localidad</p>
                                    <Input id="CP" type="text" style={{ width: '90%' }} onChange={(evt) => { setLocalidad(evt.target.value); }} value={localidad} />
                                </Grid>
                                <Grid style={{ width: '50%' }}>
                                    <p>Email</p>
                                    <Input required id="Localidad" type="text" style={{ width: '90%' }} onChange={(evt) => { setEmail(evt.target.value); }} value={email} />
                                </Grid>
                                <Grid style={{ width: '50%' }}>
                                    <p>Contacto</p>
                                    <Input id="Contacto" type="text" style={{ width: '90%' }} onChange={(evt) => { setContacto(evt.target.value); }} value={contacto} />
                                </Grid>
                                <Grid style={{ width: '50%' }}>
                                    <p>Web</p>
                                    <Input id="Web" type="text" style={{ width: '90%' }} onChange={(evt) => { setWeb(evt.target.value); }} value={web} />
                                </Grid>
                                <Grid style={{ width: '50%' }}>
                                    <p>Ubicacion</p>
                                    <Input id="Localidad" type="text" style={{ width: '90%' }} onChange={(evt) => { setUbicacion(evt.target.value); }} value={ubicacion} />
                                </Grid>
                                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <Button  type='submit' style={{ width: '154px', marginTop: '15px' }} variant="contained" >Editar Socio</Button>
                                </Grid>
                            </Grid>
                            </form>
                        </Box>
                    </Modal>

                </Grid>
                <Modal
                    open={modalDelete}
                    onClose={handleCloseDelete}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Grid sx={style} xs={4} md={6} className={'modalDeleteSocio'}>
                        <h4 style={{textAlign: "center"}}>¿Desea borrar esté socio?</h4>
                        <Grid item xs={4} md={12} className={'containerButtonModalDeleteSocio'}>
                            <Grid item className="text-center">
                                <Button variant="outlined" className="btnModalDeleteSocio" onClick={() => {
                                    setModalDelete(false);
                                    handleClose();
                                }}
                                style={{ alingItem: 'center', marginRight:'15px'}}
                                >
                                    Cancelar
                                </Button>
                            </Grid>
                            <Grid item >
                                <Button variant="outlined" className="btnModalDeleteSocio" color="error" onClick={() => {
                                    setModalDelete(false);
                                    borrarSocio(idSocio);
                                    handleClose();
                                }}>Borrar
                                    </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Modal>
            </Grid>
            </>
        );
    }
}


export default TablaSocios