import React, { useState, useRef } from "react";
import { Button, ButtonGroup, Grid, Input } from "@mui/material";
import { useEffect } from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons'
import { agregarSocio } from "../../service/ServiceAbm.jsx";
import { permisos } from "../../service/ServiceAbm.jsx";
import MapSection from "../MapSection/MapSection";
import Header from "../Header";
import './Home.css'
import AboutUsSection from "../AboutUsSection/AboutUsSection";
import ParticipationsSection from "../Participation/ParticipationsSection";
import StartSection from "../StartSection/StartSection.jsx";
import Footer from "../Footer/Footer";
import '../../styles/styles.css'
import PersistentDrawerLeft from "../DrawerMobile/PersistentDrawerLeft.tsx";
import TablaSocios from "../TablaSocios/TablaSocios.jsx";
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { Outlet, useNavigate } from "react-router-dom"

const Home = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [email, setEmail] = useState()
  const [razonSocial, setRazonSocial] = useState()
  const [residencia, setResidencia] = useState()
  const [domicilio, setDomicilio] = useState()
  const [telefono, setTelefono] = useState()
  const [codigoPostal, setCodigoPostal] = useState()
  const [localidad, setLocalidad] = useState()
  const [contacto, setContacto] = useState()
  const [web, setWeb] = useState()
  const [ubicacion, setUbicacion] = useState()
  const [data, setData] = useState()
  const map = useRef()
  const footerRef = useRef()
  const [drawerOpen, setDrawerOpen] = useState(false);

  const navigate = useNavigate()


  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const [permiso, setPermiso] = useState(null);

  const getPermiso = async () => {
    const response = await permisos();
    setPermiso(response);
  };
  useEffect(() => {
    getPermiso();
  }, []);


  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const style = {
    display: 'flex',
    flexDirection: 'column',
    flexWarp: 'warp',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '70%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: 'none',
    p: 2,
  }
  const handleSubmit = (event) => {
   
    event.preventDefault(); // Evita que se recargue la página al enviar el formulario
    handleClose();
    agregarSocio(razonSocial, residencia, domicilio, telefono, codigoPostal, localidad, email, contacto, web, ubicacion);
    handleKeyDown(event)
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }
  

  return (

    <>

      <Grid direction="column" width="window.screen.width" alignItems="center" justifyItems="center" marginTop="30">
          <>
            <StartSection/>
            <Grid md={12} className='buttonVerTodos'>
              <Button className="btnSelecVerTodos"  onClick={()=>{navigate('/participaciones')}}>VER TODAS</Button>
            </Grid>
            <Grid container md={10} xs={6} className='containerTable'/>
            <table className='containerTable'>
              <tbody>
                <thead className="theadTable" md={10}>
                  <th className="thTable"> <p className="thDominioComercial">Razon Social </p></th>
                  <th className="thTable thDominioComercial"> <p className="thDominioComercial"> Domicilio Comercial</p></th>
                  <th className="thTable"> <p className="thDominioComercial">Teléfono</p></th>
                  <th className="thTable"> <p className="thDominioComercial">Email</p></th>
                  <th className="thTable"> <p className="thDominioComercial">Web</p></th>
                  <th className="thTable"> <p className="thDominioComercial">Ubicación</p></th>
                  {permiso
                    &&
                    <th className="thTable">
                      <Button onClick={() => {
                        handleOpen();
                      }}
                              style={{ color: '#BC6C25', border: '1px solid' }}>
                  + Agregar
                        </Button>
                    </th>}
                </thead>
                <Grid className="displayNoneAbmDesktop mobileNuestroSocios">
                {permiso
                  &&
                  <Button onClick={() => {
                    handleOpen();
                  }}
                  style={{ color: '#BC6C25', border: '1px solid' }}>
                     + Agregar
                  </Button>
                }
                <h2 className="thHeadMobile nuestrosSocios"> <p className="pH2Mobile">Nuestro Socios</p> <hr></hr></h2>
              </Grid>
              <Grid item md={12} xs={12}>
                <TablaSocios limit={5} />
                <Grid md={12} className='buttonVerTodos'>
                  <Button onClick={() => navigate("/socios")} className="btnSelecVerTodos">VER LISTA COMPLETA</Button>
                </Grid>
              </Grid>
            </tbody>
          </table>
          <Grid>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} xs={8} md={12} className={'modalAmbSocios'}>
                <Button style={{ width: '24px', backgroundColor: 'white' }} variant="contained" onClick={() => { handleClose(); }}>
                  <FontAwesomeIcon onCle={handleClose} icon={faArrowLeftLong} fontSize='25px' color="#BC6C25" />
                </Button>
                <form onSubmit={handleSubmit}>
                  <Grid md={12} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>
                    <Grid style={{ width: '50%' }}>
                      <p>Razon Social</p>
                      <Input required id="RazonSocia" style={{ width: '90%' }} type="text" onChange={(evt) => { setRazonSocial(evt.target.value); }} value={razonSocial} />
                    </Grid>
                    <Grid style={{ width: '50%' }}>
                      <p>Residencia</p>
                      <Input id="Residencia" style={{ width: '90%' }} type="text" onChange={(evt) => { setResidencia(evt.target.value); }} value={residencia} />
                    </Grid>
                    <Grid style={{ width: '50%' }}>
                      <p>Domicilio</p>
                      <Input id="Domicilio" style={{ width: '90%' }} type="text" onChange={(evt) => { setDomicilio(evt.target.value); }} value={domicilio} />
                    </Grid>
                    <Grid style={{ width: '50%' }}>
                      <p>Telefono</p>
                      <Input id="Telefono" style={{ width: '90%' }} type="text" onChange={(evt) => { setTelefono(evt.target.value); }} value={telefono} />
                    </Grid>
                    <Grid style={{ width: '50%' }}>
                      <p>CP</p>
                      <Input id="CP" type="text" style={{ width: '90%' }} onChange={(evt) => { setCodigoPostal(evt.target.value); }} value={codigoPostal} />
                    </Grid>
                    <Grid style={{ width: '50%' }}>
                      <p>Localidad</p>
                      <Input id="CP" type="text" style={{ width: '90%' }} onChange={(evt) => { setLocalidad(evt.target.value); }} value={localidad} />
                    </Grid>
                    <Grid style={{ width: '50%' }}>
                      <p>Email</p>
                      <Input required id="Localidad" type="text" style={{ width: '90%' }} onChange={(evt) => { setEmail(evt.target.value); }} value={email} />
                    </Grid>
                    <Grid style={{ width: '50%' }}>
                      <p>Contacto</p>
                      <Input id="Contacto" type="text" style={{ width: '90%' }} onChange={(evt) => { setContacto(evt.target.value); }} value={contacto} />
                    </Grid>
                    <Grid style={{ width: '50%' }}>
                      <p>Web</p>
                      <Input id="Web" type="text" style={{ width: '90%' }} onChange={(evt) => { setWeb(evt.target.value); }} value={web} />
                    </Grid>
                    <Grid style={{ width: '50%' }}>
                      <p>Ubicacion</p>
                      <Input id="Localidad" type="text" style={{ width: '90%' }} onChange={(evt) => { setUbicacion(evt.target.value); }} value={ubicacion} />
                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Button type='submit' style={{ width: '154px', marginTop: '15px' }} variant="contained">Agregar Socio</Button>
                    </Grid>
                  </Grid>
                  </form>
              </Box>
            </Modal>

          </Grid></>
        <section id="sectionMap" >
          <Grid ref={map} container md={12} xs={12} className="mapSection">
            <MapSection></MapSection>
          </Grid>
        </section>
        <Grid ref={footerRef}>
          <section id="sectionFooter">
            <Footer />
          </section>
        </Grid>
      </Grid>
    </>

  )

}


export default Home