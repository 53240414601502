import React from 'react'
import { ButtonGroup, Button } from '@mui/material';
import { Grid } from "@mui/material";
import { useEffect } from "react";
import TheProcess from './TheProcess/TheProcess';
import Applications from './Applications/Applications';
import Basics from './Basics/Basics';
import './AboutUsSection.css'


const SwitchRender = (selected) => {
    switch (selected) {
        case 'El Proceso': return <TheProcess />
        case 'Usos': return <Applications />
        case 'Aspectos Basicos': return <Basics />
        default: return <TheProcess />
    }
}
const AboutUsSection = () => {
    const [selected, setSelected] = React.useState('El Proceso');
    let arrayItemNavigation = ['El Proceso', 'Usos', 'Aspectos Basicos'];


    return (
             <>
            <Grid container md={12} sx={12} className='aboutSectionContainer'>
            <section id="#aboutSection">
            <Grid item md={8} xs={12} className='aboutSectionNavigator'>
                {arrayItemNavigation.map((item) => (
                    <ButtonGroup color="inherit"

                        xs={10}
                        size="small"
                        className={[selected === item ? 'btnSelected' : 'btnNotSelected']}>
                        <Button
                            onClick={() => setSelected(item)}
                        >
                            <text
                                className={selected === item ? 'textSelected' : 'textNotSelected'}>
                                {item}
                            </text>
                        </Button>
                    </ButtonGroup>

                ))}
            </Grid>
            </section>
            <Grid className='renderSection'>
                {SwitchRender(selected)}
            </Grid>
        </Grid></>

    )

}

export default AboutUsSection
