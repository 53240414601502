import TablaSocios from "../TablaSocios/TablaSocios"
import { Button, Grid } from "@mui/material";
import './socios.css'

const Socios = () => {

    return (
        <>
            <Grid md={12} xs={10} className='pageSocios' style={{position:'sticky', top: '20px'}} >
                <a href="https://camaradelapiedra.org.ar/api/socio/socios" className="aLinkExel">
                    <Button className="btnSelecVerTodos">Descargar Listado</Button>
                </a>
            </Grid>
            <Grid item md={12} xs={12} className='fullTabla'>
                <TablaSocios limit={0} />
            </Grid>
        </>
    )
}


export default Socios