import React, { useRef } from 'react';
import { useState } from 'react'
import { Map, TileLayer, Marker } from 'react-leaflet';
import { Button, Grid } from "@mui/material";
import '../MapSection/MapSection.css'
import 'leaflet/dist/leaflet.css';
import iconMap from '../../assets/iconMap.png'
import L from 'leaflet'


const defaultCenter = [-37.328673, -59.153137];
const defaultZoom = 10;


const DosArryos = [-37.06243506327674, -59.945802764893315];
const DomingoGonzalez = [-34.904583, -57.796843];
const Marengo = [-37.316060, -59.982975];
const LaPonderosa = [-37.067124, -59.950325];
const CerroFederacion = [-37.308131, -59.237612];
const Equimac = [-37.305733220494965, -59.26605731628632];
const MineraTandil = [-37.371444, -58.969681];
const CanteraYaravi = [-38.017838, -57.738173];
const PiroYRuiz = [-38.074674, -62.464393];
const AdolfoGuerrico = [-36.89870725502359, -60.13888835989362];
const Piatti = [-36.96128122377125, -60.106674459457736];
const Aguila = [-36.89098804271164, -60.32337318732141];
const Galasur = [-36.83771311969857, -60.25080008644575];
const CerroRedondo = [-36.99638970908773, -60.118656783191504];
const CanterasArgentinas = [-36.95890041120613, -60.18017038771023];
const CerroDomicos = [-37.348544792245036, -58.93399723916396];
const MineraElDestino  = [-37.752450729161936, -58.57473566267398];
const CruzOmarPavone  = [-36.92539396138625, -60.15722719883329];
const LomaNegraCIASA  = [-37.63455661788615, -59.46236587194196];
const PolcecalSA  = [-36.92539396138625, -60.15722719883329];
const SucesiondeAmbrosioColomboSRL  = [-36.9175526251524, -60.21010427280956];

const positions = [ CanterasArgentinas ,CerroRedondo,DosArryos, DomingoGonzalez, Marengo,
                    LaPonderosa, CerroFederacion, Equimac, MineraTandil, CerroDomicos,
                    CanteraYaravi, PiroYRuiz, AdolfoGuerrico, Piatti, Aguila,
                    Galasur, CanterasArgentinas, MineraElDestino,CruzOmarPavone,
                    LomaNegraCIASA,  PolcecalSA, SucesiondeAmbrosioColomboSRL,
                ];

const MapSection = () => {

    const [height, setHeight] = useState(0)
    const ref = useRef(null)

    const [cordenadas, setCordendas] = useState(CerroFederacion)
    const customIcon = new L.Icon({
        iconUrl: iconMap,
        iconSize: [50, 65],
        iconAnchor: [22, 94],
        popupAnchor: [-3, -76],
    });

    const mapRef = useRef();
    function handleFly(place) {
        const { current = {} } = mapRef;
        const { leafletElement: map } = current;

        map.flyTo(place, 12, {
            duration: 2
        });
        setCordendas(place)
    }
    return (
        <>
            <Grid container xs={12} className="mapSectionContainer">
                <Grid item md={4} xs={10} className="citySectionContainer" ref={ref}>
                    <p className="titleCityMap">Ubicación Geográfica
                        <hr></hr>
                    </p>
                    <ul className="nameCityMap">BERISSO</ul>
                    <Button onClick={() => handleFly(DomingoGonzalez)}>
                        <li className="liCityMap">Domingo Gonzalez y Cia. S.A.</li>
                    </Button>
                    <ul className="nameCityMap">Azul</ul>
                    <Button onClick={() => handleFly(DosArryos)}>
                        <li className="liCityMap">Constructura Dos Arroyos S.A.</li>
                    </Button>
                    <Button onClick={() => handleFly(Marengo)}>
                        <li className="liCityMap"> Marengo S.A.</li>
                    </Button>
                    <Button onClick={() => handleFly(LaPonderosa)}>
                        <li className="liCityMap">La Ponderosa S.A.</li>
                    </Button>
                    <ul className="nameCityMap">Tandil</ul>
                    <Button onClick={() => handleFly(CerroFederacion)}>

                        <li className="liCityMap">Cerro Federación</li>
                    </Button>
                    <Button onClick={() => handleFly(CerroDomicos)}>
                        <li className="liCityMap">Cerros Domicos S.A.</li>
                    </Button>
                    <Button onClick={() => handleFly(Equimac)}>
                        <li className="liCityMap">Equimac S.A.</li>
                    </Button>
                    <Button onClick={() => handleFly(MineraTandil)}>
                        <li className="liCityMap">Minera Tandil S.A.</li>
                    </Button>
                    <ul className="nameCityMap">CHAPADMALAL</ul>
                    <Button onClick={() => handleFly(CanteraYaravi)}>
                        <li className="liCityMap">Canteras Yaravi S..A.</li>
                    </Button>
                    <ul className="nameCityMap">TORNQUIST</ul>
                    <Button onClick={() => handleFly(PiroYRuiz)}>
                        <li className="liCityMap">Piro y Ruiz S.C.A.</li>
                    </Button>
                    <ul className="nameCityMap">OLAVARRIA</ul>

                    <Button onClick={() => handleFly(AdolfoGuerrico)}>

                        <li className="liCityMap">Adolfo Guerrico S.A.</li>
                    </Button>
                    <Button onClick={() => handleFly(CanterasArgentinas)}>
                        <li className="liCityMap"> Canteras Argentinas SA</li>
                    </Button>
                    <Button onClick={() => handleFly(CerroRedondo)}>
                        <li className="liCityMap"> Cerro Redondo</li>
                    </Button>
                    <Button onClick={() => handleFly(Piatti)}>
                        <li className="liCityMap">Cantera Piatti S.A.</li>
                    </Button>
                    <Button onClick={() => handleFly(Aguila)}>

                        <li className="liCityMap">Cerro del Águila de Olavarria S.A.</li>
                    </Button>
                    <Button onClick={() => handleFly(Galasur)}>

                        <li className="liCityMap">Galasur S.A.</li>
                    </Button>
                    <ul className="nameCityMap">Balcarce</ul>
                    <Button onClick={() => handleFly(MineraElDestino)}>
                        <li className="liCityMap"> Compañía Minera El Destino SA</li>
                    </Button>
                    <Button onClick={() => handleFly(CruzOmarPavone)}>
                        <li className="liCityMap"> Cruz Omar Pavone SRL</li>
                    </Button>
                    <Button onClick={() => handleFly(LomaNegraCIASA)}>
                        <li className="liCityMap"> Loma Negra CIASA</li>
                    </Button>
                    <Button onClick={() => handleFly(PolcecalSA)}>
                        <li className="liCityMap"> Polcecal SA</li>
                    </Button>
                    <Button onClick={() => handleFly(SucesiondeAmbrosioColomboSRL)}>
                        <li className="liCityMap">Sucesión de Ambrosio Colombo SRL</li>
                    </Button>
                </Grid>
                <Grid item className={'containerMapa'} md={7} xs={12}>
                    <Map ref={mapRef} center={defaultCenter} zoom={defaultZoom}>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        {positions.map((position, index) => (
                            <Marker key={index} position={position} icon={customIcon} />
                        ))}
                    </Map>

                </Grid>
            </Grid>

        </>
    )


}

export default MapSection
