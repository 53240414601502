import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import banner from '../../assets/banner.png'
import { Button } from '@mui/material';
import './PersistentDrawerLeft.css'
import { useNavigate } from "react-router-dom";
import { useState } from 'react';


const drawerWidth = 240;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft() {
    const [path, setPath] = useState('/');

    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const navigate = useNavigate();

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar position="fixed" open={open}>
                <Toolbar sx={{ backgroundColor: 'white' }}>
                    <IconButton
                        color="inherit"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }), color: 'black' }}
                    >
                        <MenuIcon />
                    </IconButton>

                    {!open && <img src={banner} width="50%" />}
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <div className='backgroundDrawer'>
                    <DrawerHeader
                    >
                        <img src={banner} width='150px' />
                        <IconButton onClick={handleDrawerClose}>
                            <ChevronRightIcon />
                        </IconButton>
                    </DrawerHeader>
                </div>
                <Divider />
                <List style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="DrawerMenu">
                        <Button style={{ width: '150px', color: '#BC6C25', backgroundColor: path === '/' ? '#BC6C25' : 'white' }}
                            onClick={() => {
                                navigate('/');
                                setPath('/');
                                handleDrawerClose();
                            }}>
                              <p className='textDrawer' style={{ color:  path === '/' ?  'white' :'#BC6C25'  }}>Inicio</p>
                        </Button>
                        <Button style={{ height:'75px' ,width: '150px', color: '#BC6C25', backgroundColor: path === '/sobre-nosotros' ? '#BC6C25' : 'white'  }}
                            onClick={() => {
                                navigate('/sobre-nosotros');
                                setPath('/sobre-nosotros');
                                handleDrawerClose();
                            }}>
                               <p className='textDrawer' style={{ color:  path === '/sobre-nosotros' ?  'white' :'#BC6C25'  }}>Sobre Nosotros</p>
                        </Button>
                        <Button style={{ width: '150px', color: '#BC6C25', backgroundColor: path === '/participaciones' ? '#BC6C25' : 'white' }} onClick={() => {
                             navigate('/participaciones');
                             setPath('/participaciones');

                             handleDrawerClose();              
                            }}>
                               <p className='textDrawer' style={{ color:  path === '/participaciones' ?  'white' :'#BC6C25'  }}>Participantes</p>
                        </Button>
                        <Button style={{ width: '150px', color: '#BC6C25' }} href={'#sectionMap'} onClick={() => {
                            navigate('/');
                            setPath('/');
                            handleDrawerClose();
                        }} >
                            <p className='textDrawer'>Mapa</p>
                        </Button>
                        <Button style={{ width: '150px', color: '#BC6C25' }} href={'#sectionFooter'} onClick={() => {
                            navigate('/');
                              setPath('/');
                            handleDrawerClose();
                        }}>
                            <p className='textDrawer'>Contacto</p>
                        </Button>

                    </div>
                </List>
                <Divider />
            </Drawer>
            <Main open={open}>
                <DrawerHeader />
            </Main>
        </Box>
    );
}