import Home from './components/Home/Home';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AboutUsSection from "./components/AboutUsSection/AboutUsSection";
import ParticipationsSection from './components/Participation/ParticipationsSection';
import Layout from "./components/Layout/Layout";
import Socios from './components/Socios/socios';


function App() {
  return (
      <Router>
        <Routes>
          <Route element={<Layout />}>
            <Route path='/' element={<Home />} />
            <Route path='/sobre-nosotros' element={<AboutUsSection />} />
            <Route path='/participaciones' element={<ParticipationsSection />} />
            <Route path='/socios' element={<Socios/>} />
          </Route>
        </Routes>
      </Router>
  )
}

export default App;