import React from "react";
import { Grid } from "@mui/material";
import banner from '../assets/banner.png'

const Header = () => {
    return (
        <Grid display="flex" width="100%" alignItems="center" justifyContent="center">
            <img src={banner} width="40%" />
            
        </Grid>
    )
}

export default Header