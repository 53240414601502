import React from "react";
import Grid from '@mui/material/Grid';
import { useEffect } from "react";
import applications1 from '../../../assets/applications1.jpg';
import applications2 from '../../../assets/applications2.jpg';
import './Applications.css'

const Applications = () => {
    return (
        <Grid container xs={10} md={10} className='applicationsContainer'>
            <Grid item xs={12} md={12} className='applicationsAridos' >
                <Grid item xs={12} md={4}>
                    <Grid item md={12} xs={10}>
                        <p className="applicationsTitle">
                            ¿Para que sirven los áridos?
                        </p>
                        <p className="applicationsText">

                            Los áridos son materiales económicos que se pueden
                            obtener en grandes cantidades y, desde hace muchos
                            años, son la base en todo tipo de construcción. Pero las
                            múltiples utilidades que tienen en la actualidad lo
                            convierten en un material indispensable en nuestra sociedad
                            sociedad, tanto a nivel industrial como cotidiano.
                        </p>
                        <p className="applicationsSpecialText">
                            Se estima que una persona utiliza unas 11
                            toneladas al año, de lo que se deduce
                            que en toda una vida consumirá,
                            cerca de 850.000 kilogramos de áridos, cantidad
                            equivalente a la transportada en 28
                            camiones.
                        </p>
                    </Grid>
                </Grid>
                <Grid item sx={12} md={5}>
                    <img src={applications1} className={'imgApplications1'}/>
                </Grid>
            </Grid>
            <Grid item xs={12} md={10} className='applicationsAridosAplicaiones'>
                <Grid xs={12} md={6}>
                    <Grid item md={11} xs={10}>
                        <p className="applicationsSubTitle">
                            Los áridos también tienen
                            muchas aplicaciones para
                            mejorar el medio ambiente:
                        </p>
                        <p className="applicationsText" >
                            Reducción de las emisiones de azufre, reducción de la
                            acidez de los suelos, filtros para la depuración de
                            aguas, control de procesos erosivos, desulfuración en
                            Tratamiento de aguas. Filtración de aguas de consumo
                            humano, depuración de aguas residuales y
                            neutralización de aguas ácidas.
                        </p>

                        <p className="applicationsTitle">
                            En la construcción:
                        </p>
                        <p className="applicationsText">
                            Los áridos se utilizan en todo tipo de construcción, y a
                            su vez son materia prima de otros productos de la
                            construcción: Minerales industriales; Áridos
                            industriales; Áridos industriales; Revestimientos aislantes y refractarios;
                            Fabricación de acero; Fabricación del cemento y de la
                            cal; Lechos filtrantes.
                        </p>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={5}>
                    <img src={applications2} className={'imgApplications2'}/>
                </Grid>
            </Grid>
            <Grid item xs={10} md={4} className='applicationsFooter'>
                <Grid item xs={10} md={4}>
                    <p style={{ fontWeight: 700, color: '#BC6C25' }}>
                        | Mi casa, mi hogar
                    </p>
                </Grid>
                <Grid item xs={10} md={4}>
                    <p style={{ fontWeight: 700, color: '#BC6C25' }}>
                        | Minería en casa
                    </p>
                </Grid>
                <Grid item xs={10} md={4}>
                    <p style={{ fontWeight: 700, color: '#BC6C25', }}>
                        | Tiempo de sembrar
                    </p>
                </Grid>
            </Grid>
        </Grid>

    )
}


export default Applications