import React, { useEffect, useState } from 'react';
import { Button, Grid } from "@mui/material";
import Header from "../Header";
import '../../styles/styles.css'
import PersistentDrawerLeft from "../DrawerMobile/PersistentDrawerLeft.tsx";
import { Outlet, useNavigate } from "react-router-dom"
const Layout = () => {
  const [path, setPath] = useState('/');

  useEffect(() => {
    setPath(window.location.pathname);

  }, [window.location.pathname]);
  const navigate = useNavigate()

  return (
    <>
      <Grid direction="column" width="window.screen.width" alignItems="center" justifyItems="center" marginTop="30">
        <Grid className="displayNoneAbmDesktop">
          <PersistentDrawerLeft />
        </Grid>
        <>
          <Grid className="displayNoneAbmMobile" >
            <Header />
          </Grid>
          <Grid
            container
            className="displayNoneAbmMobile"
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              zIndex: 99999999999999, // Aumentar el valor de zIndex
              backgroundColor: 'white',
              position: "sticky",
              top: '0px'
            }}
            md={12}
            xs={12}
          >            <Grid item style={{ marginTop: '25px', marginBottom: '25px', }}>
              <Button style={{ width: '170px', height: '35px', backgroundColor: path === '/' ? '#BC6C25' : 'white' }} onClick={() => { navigate("/"); setPath(window.location.pathname) }} className={path === '/' ? 'btnSelected' : 'btnNotSelected'}>
                <p style={{ color: path === '/' ? 'white' : '#BC6C25' }}>
                  Inicio
                </p>
              </Button>
              <Button style={{ width: '170px', height: '35px', backgroundColor: path === '/sobre-nosotros' ? '#BC6C25' : 'white' }} onClick={() => { navigate("/sobre-nosotros"); setPath(window.location.pathname) }}>
                <p style={{ color: path === '/sobre-nosotros' ? 'white' : '#BC6C25' }}>
                  Sobre Nosotros
                </p>
              </Button>
              <Button style={{ width: '170px', height: '35px', backgroundColor: path === '/participaciones' ? '#BC6C25' : 'white' }} onClick={() => { navigate("/participaciones"); setPath(window.location.pathname) }}>
                <p style={{ color: path === '/participaciones' ? 'white' : '#BC6C25' }}>
                  Participaciones
                </p>
              </Button>
              <Button style={{ width: '170px', height: '35px' }} onClick={() => navigate("/")} href="#sectionMap">
                <p style={{ color: '#BC6C25' }}>
                  Mapa
                </p>
              </Button>
              <Button href={'#sectionFooter'} style={{ width: '150px', height: '35px' }} onClick={() => navigate("/")}>
                <p style={{ color: '#BC6C25' }}>
                  Contacto
                </p>
              </Button>
            </Grid>
          </Grid>
        </>
        <Outlet />
      </Grid>
    </>
  )
}


export default Layout;