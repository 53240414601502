import { Grid } from '@mui/material'
import React from 'react'
import './Participation.css'
import participation1 from '../../assets/participation1.jpeg'
import participation2 from '../../assets/participation2.jpeg'
import participation3 from '../../assets/participation3.jpeg'
import participation4 from '../../assets/participation4.jpeg'
import participation5 from '../../assets/participation5.jpeg'


const ParticipationsSection = () => {

    return (
        <Grid container md={12} sx={10} className='globalContainerParticipations'>
            <Grid item md={10} sx={10} className='containerParticipations'>
                <Grid item md={6} sx={10}>
                    <Grid item md={12} sx={10}>

                        <p className='yearsParticipations'>2013</p>
                        <ul>
                            <li>IX Exposición Internacional de Minería, ARMINERA, Ciudad Autónoma de Buenos Aires, Argentina.</li>
                            <li>Expo Rural de Olavarría, Pcia. Bs. As., Argentina</li>
                        </ul>
                    </Grid>
                    <Grid item md={12} sx={10}>

                        <p className='yearsParticipations'>2012</p>
                        <ul>
                            <li>IIII Congreso de Áridos, Cáceres, España</li>
                            <li>XVI Congreso de Vialidad y transporte, Córdoba, Argentina</li>
                            <li>Expo Rural de Olavarría, Pcia. Bs. As., Argentina</li>
                        </ul>
                    </Grid>
                    <Grid item md={12} sx={10}>

                        <p className='yearsParticipations'>2011</p>
                        <ul>
                            <li>IX Jornadas de Iberoamericanas de materiales para la construcción, Quito, Ecuador</li>
                            <li>IAsamblea Anual de la Federación Iberoamericana de Productores de Áridos, Quito, Ecuador.</li>
                            <li>Expo Rural de Olavarría, Pcia. Bs. As., Argentina</li>
                        </ul>
                    </Grid>
                </Grid>
                <Grid item sx={10} md={6}>
                    <Grid item>
                    <Grid item sx={10} md={10} className='divImgParticipations' display='flex'>
                            <img src={participation1} className='imgParticipation1'/>
                        </Grid>
                    </Grid>
                    <Grid item>
                    <Grid item sx={10} md={10} className='divImgParticipations' display='flex'>
                            <img src={participation2} className='imgParticipation2'/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={12} sx={10}className='containerParticipations'>
            <Grid item sx={10} md={6} className='divImgParticipations specialDesktopImg' display='flex'>
                        <img src={participation3} className='imgParticipation3'/>
                    </Grid>
                <Grid item md={4} sx={10}>
                    <Grid item md={12} sx={10}>
                        <p className='yearsParticipations'>2010</p>
                        <ul>
                            <li>III Congreso de Educación de la Provincia de Buenos Aires- Educación, empresa y Sociedad, Olavarría, Pcia. de Bs. As.</li>
                            <li>VIII Jornadas de Iberoamericanas de materiales para la construcción, Lima, Perú.</li>
                            <li>Asamblea Anual de la Federación Iberoamericana de Productores de Áridos, Lima, Perú.</li>
                            <li>Foro Mujeres Trabajando en la Minería, Hotel Emperador, Ciudad Autonoma de Buenos Aires, Argentina.</li>
                            <li>Expo Rural de Olavarría, Pcia. Bs. As., Argentina</li>   
                        </ul>
                    </Grid>
                    <Grid item md={12} sx={10}>

                        <p className='yearsParticipations'>2009</p>
                        <ul>
                            <li>Gestión en Canteras Olavarría, Ciudad de Olavarría, Pcia. de Bs. As., Argentina</li>
                            <li>2° Congreso de Áridos, Valencia, España</li>
                            <li>VII Jornadas de Iberoamericanas de materiales para la construcción, Valencia, España.</li>
                            <li>Asamblea Anual de la Federación Iberoamericana de Productores de Áridos, Valencia, España.</li>
                            <li>Expo Rural de Olavarría, Pcia. Bs. As., Argentina</li>
                        </ul>
                    </Grid>

                </Grid>

            </Grid>
            <Grid item md={10} sx={10} className='containerParticipations'>
                <Grid item md={6} sx={10}>
                    <Grid item md={10} sx={10}>
                        <p className='yearsParticipations'>2008</p>
                        <ul>
                            <li>1° Congreso Argentino de Áridos 2008- " Los Áridos como factor de desarrollo", organizado por la Cámara de la Piedra de la Provincia de Buenos Aires, Mar del Plata, Argentina.</li>
                            <li>VII Jornadas de Iberoamericanas de materiales para la construcción, Mar del Plata, Argentina</li>
                            <li>Expo Rural de Olavarría, Pcia. Bs. As., Argentina</li>
                        </ul>
                    </Grid>
                    <Grid item md={10} sx={10}>
                        <p className='yearsParticipations'>2007</p>
                        <ul>
                            <li>VI Jornadas de Iberoamericanas de materiales para la construcción, Ciudad de Panamá, Panamá</li>
                            <li>Asamblea Anual de la Federación Iberoamericana de Productores de Áridos, Ciudad de Panamá, Panamá</li>
                            <li>Expo Rural de Olavarría, Pcia. Bs. As., Argentina</li>
                        </ul>
                    </Grid>
                    <Grid item md={10} xs={10}>
                        <p className='yearsParticipations'>2006</p>
                        <ul>
                            <li>Asamblea Anual de la Federación Iberoamericana de Productores de Áridos, Ciudad de Panamá, Panamá</li>
                        </ul>
                        <p className='subtitleParticipations'>Territorial</p>
                        <ul>
                            <li>V Jornadas de Iberoamericanas de materiales para la construcción, Bogotá, Colombia</li>
                            <li>Asamblea Anual de la Federación Iberoamericana de Productores de Áridos , Bogotá, Colombia</li>
                            <li>"Minería Argentina, una Industria para la vida", CAEM, Mar del Plata, Argentina.</li>
                            <li>1° Congreso de Áridos de Zaragoza</li>
                        </ul>
                    </Grid>
                </Grid>
                <Grid item sx={10} md={6}>
                    <Grid item>
                    <Grid item sx={10} md={10} className='divImgParticipations' display='flex'>
                            <img src={participation4} className='imgParticipation1'/>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid item sx={10} md={10} className='divImgParticipations' display='flex'>
                            <img src={participation5} className='imgParticipation5'/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ParticipationsSection;
